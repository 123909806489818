import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { AppLogo } from "../../../components/ui";
import { BasicLayout } from "../../../layouts/BasicLayout";
import theme from "../../../util/theme";
import {
  APP_OF_THE_YEAR_COUPON,
  COUPON_DISCOUNT_DATA,
  DEFAULT_CURRENCY_CODE,
  PROMO_MAP,
  mobileSize,
} from "../../../util/variables";
import { navigate } from "gatsby";
import { signInWithApple, signInWithGoogle } from "../../../services/firebase-sso";
import {
  discount_flow_appear,
  discount_flow_applesso_tapped,
  discount_flow_create_account_fail,
  discount_flow_create_account_start,
  discount_flow_create_account_success,
  discount_flow_emailSwitch,
  discount_flow_googlesso_tapped,
} from "../../../services/mixpanel/discount-flow-events";
import { trackAndRedirectUser } from "../../../components/PageSegments/subscription/AccountCouponForm";
import { LogInForm } from "../../../components/ui/LogInForm";
import { LocalPriceContext } from "../../../providers/LocalPriceProvider";

export default (data: any) => {
  const { location } = useContext(LocalPriceContext);
  const { search, state } = data.location;
  const urlParams = new URLSearchParams(search);
  const couponParam = state?.couponId ?? urlParams.get("cid");
  const flowParam = urlParams.get("from");
  // currently used to determine if the user is a new user or a returning paid user
  // new_user === free trial; else no free trial
  const typeParam = urlParams.get("type");
  const hasFreeTrial = state?.trial ?? typeParam === "new_user";
  const priceIdParam = state?.priceId ?? urlParams.get("pid");

  useEffect(() => {
    if (!!couponParam && !COUPON_DISCOUNT_DATA.find(item => item.id === couponParam)) {
      navigate("/");
    }

    if (couponParam === APP_OF_THE_YEAR_COUPON) {
      localStorage.setItem("promo", PROMO_MAP[APP_OF_THE_YEAR_COUPON]);
    }

    if (typeParam) {
      localStorage.setItem("flow", `discount - ${typeParam.replace("_", " ")}`);
    } else if (flowParam) {
      localStorage.setItem("flow", `discount - ${flowParam}`);
    } else {
      localStorage.setItem("flow", `discount - support`);
    }

    discount_flow_appear();
  }, [couponParam, flowParam, typeParam]);

  const handleGoogleLogin = async () => {
    const SSO_PROVIDER = "google";
    discount_flow_googlesso_tapped();
    discount_flow_create_account_start(SSO_PROVIDER);
    const res = await signInWithGoogle();

    // eslint-disable-next-line no-negated-condition
    if (typeof res !== "boolean") {
      discount_flow_create_account_success(SSO_PROVIDER, res.user.uid, res.user.email, false);
      await trackAndRedirectUser({
        uid: res.user.uid,
        email: res.user.email,
        cid: couponParam,
        trial: hasFreeTrial,
        priceId: priceIdParam,
        currency_code: location?.currency.code ?? DEFAULT_CURRENCY_CODE,
      });
    } else {
      discount_flow_create_account_fail(SSO_PROVIDER, "Failed to login with google");
    }
  };

  const handleAppleLogin = async () => {
    const SSO_PROVIDER = "apple";
    discount_flow_applesso_tapped();
    discount_flow_create_account_start(SSO_PROVIDER);
    const res = await signInWithApple();

    // eslint-disable-next-line no-negated-condition
    if (typeof res !== "boolean") {
      discount_flow_create_account_success(SSO_PROVIDER, res.user.uid, res.user.email, false);
      await trackAndRedirectUser({
        uid: res.user.uid,
        email: res.user.email,
        cid: couponParam,
        trial: hasFreeTrial,
        priceId: priceIdParam,
        currency_code: location?.currency.code ?? DEFAULT_CURRENCY_CODE,
      });
    } else {
      discount_flow_create_account_fail(SSO_PROVIDER, "Failed to login with apple");
    }
  };

  const handleEmailLogin = (autoSelected: boolean) => {
    if (!autoSelected) {
      discount_flow_emailSwitch();
    }

    navigate(`email${search ?? ""}`, {
      state,
    });
  };

  return (
    <BasicLayout noIndex>
      <FlexContainer>
        <LogoContainer>
          <AppLogo size="medium" />
        </LogoContainer>
        <Content>
          <Title>
            {hasFreeTrial ? "Start Your 7-Day Free Trial" : "Unlock the Future of Learning"}
          </Title>
          <LogInForm
            title="Log in or Create an Account"
            handleEmailLogin={handleEmailLogin}
            handleAppleLogin={handleAppleLogin}
            handleGoogleLogin={handleGoogleLogin}
          />
        </Content>
      </FlexContainer>
    </BasicLayout>
  );
};

export const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    position: static;
    margin: 64px 0;
  }
`;

export const Title = styled.h1`
  margin: auto;
  margin-bottom: 24px;
  font-size: 35px;
  font-weight: 500;
  font-family: ${theme.SECONDARY_FONT};
  text-align: left;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    margin-bottom: 16px;
    font-size: 24px;
    /* font-weight: 700; */
  }
`;

export const Title2 = styled.h2`
  margin-top: 0;
  margin-bottom: 36px;
  font-size: 24px;
  font-weight: 500;
  font-family: ${theme.SECONDARY_FONT};
  text-align: center;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    margin-bottom: 24px;
    font-size: 18px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  max-width: 1000px;
  width: 90vw;
  padding: 80px;
  padding-bottom: 120px;
  border-radius: 16px;
  background-color: #fafafa;
  box-shadow: 0px 1.58px 11px rgba(44, 39, 56, 0.14);

  @media ${mobileSize} {
    padding: 40px 20px;
    min-height: 400px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
`;
